var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fill-width fill-height d-flex justify-center align-center text-center",
    staticStyle: {
      "height": "100vh"
    }
  }, [_c('div', [_vm._m(0), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-btn', {
    staticClass: "main darkGrey mr-3 white--text",
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-arrow-left")]), _vm._v(" go back ")], 1), _c('v-btn', {
    staticClass: "main darkGrey white--text",
    attrs: {
      "link": "",
      "to": "/"
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-home")]), _vm._v(" go home page ")], 1)], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column mb-7"
  }, [_c('h1', {
    staticClass: "font-weight-bolder",
    staticStyle: {
      "font-size": "150px"
    }
  }, [_vm._v(" 404 ")]), _c('h3', {
    staticClass: "font-weight-light"
  }, [_vm._v(" OOPS! Something went wrong here ")])]);

}]

export { render, staticRenderFns }