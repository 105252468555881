<template>
  <div
    class="fill-width fill-height d-flex justify-center align-center text-center"
    style="height:100vh"
  >
    <div>
      <div class="d-flex flex-column mb-7">
        <!-- <v-img :src="backgroundImage" /> -->
        <h1 class="font-weight-bolder " style="font-size:150px">
          404
        </h1>
        <h3 class="font-weight-light">
          OOPS! Something went wrong here
        </h3>
      </div>
      <div class="d-flex">
        <v-btn class="main darkGrey mr-3 white--text" @click="$router.go(-1)">
          <v-icon left>mdi-arrow-left</v-icon>
          go back
        </v-btn>
        <v-btn class="main darkGrey white--text" link to="/">
          <v-icon left>mdi-home</v-icon>
          go home page
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error-1",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "/media/error/bg1.jpg";
    },
  },
};
</script>
